<template>
  <div class="tree-view-box has-background-white-ter">
    <div class="tree-top-bar">
      <span class="has-text-centered confirm-text float-left" v-if="doodleForm.errorMessage">
        <font-awesome-icon icon="exclamation-circle" class="has-text-danger"/>
        {{doodleForm.errorMessage}}
      </span>
      <span class="float-right">
        <a class="has-text-grey-dark is-small min-button has-text-weight-bold tooltip" data-tooltip="Minimize" @click="$emit('compress')">
          <font-awesome-icon icon="compress"/>
        </a>
      </span>
    </div>
    <div class="tree-view-area">
      <ul class="tree">
        <TreeItem
          class="item"
          :item="treeData"
          :home="treeHome"
          :activeItem="activeItem"
          :libraries="libraries"
          :projectKey="projectKey"
          :mark-start-file="markStartFile"
          @item-activated="itemActivated"
          @make-start="makeStart"
          @folder-renamed="updateChildrensParent"
          @delete-item="deleteItem" @sort-path="sortPath" @item-moved="itemMoved"
          @add-item="addItem" @add-folder="addFolder"></TreeItem>
      </ul>
    </div>
  </div>
</template>

<script>
import TreeItem from './TreeItem'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import { IDE_CONST } from '../../assets/javascript/constants'
import formMixin from '../../assets/javascript/form-mixin'

export default {
  name: 'projectTree',
  components: { TreeItem },
  props: ['project', 'activeFile', 'projectKey', 'editableTypes', 'libraries', 'markStartFile'],
  mixins: [formMixin],
  data: function () {
    return {
      activeItem: {}
    }
  },
  computed: {
    treeData () {
      return (this.project) ? this.project.treeData : {}
    },
    treeHome () {
      return (this.project) ? this.project.home : {}
    }
  },
  created () {
    Vue.use(VuejsDialog)
  },
  watch: {
    activeFile () {
      this.itemActivated(this.activeFile)
    }
  },
  mounted () {
    document.addEventListener('keyup', (event) => {
      this.activeItem.codeChanged = true
    })
  },
  methods: {
    getParentName: function (item) {
      let parent = '/'

      if (item.parent && item.parent === '/') {
        parent = item.parent + item.name
      } else if (item.parent) {
        parent = item.parent + '/' + item.name
      }
      return parent
    },
    addFolder (item) {
      let parentName = this.getParentName(item)
      let newFolder = {
        name: '',
        editMode: true,
        parent: parentName,
        markedForDeletion: false,
        children: []
      }
      item.children.push(newFolder)
    },
    itemActivated (item) {
      let editable = false

      for (let type of this.editableTypes) {
        if (item.name.endsWith(type)) {
          editable = true
          break
        }
      }

      if (!editable) {
        this.$dialog.alert('Only ' + this.editableTypes + ' file types can be edited. For other file types, download, edit locally and upload.', {
          html: true,
          okText: 'Close'
        })
        return
      }

      this.activeItem.content = window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().getValue()
      this.activeItem = item
      if (item.content) {
        window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().setValue(item.content)
      } else {
        window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().setValue('')
      }

      if (!item.isReadOnly) {
        window.ace.edit(IDE_CONST.CODE_EDITOR).setReadOnly(false)
      } else {
        window.ace.edit(IDE_CONST.CODE_EDITOR).setReadOnly(true)
      }

      if (item.name.lastIndexOf('.') > -1) {
        let fileType = item.name.substr(item.name.lastIndexOf('.') + 1)

        if (fileType === 'js') { fileType = 'javascript' }

        window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().setMode('ace/mode/' + fileType)
      }

      this.$emit('file-changed', item)
    },
    addItem (item) {
      let parentName = this.getParentName(item)
      item.children.push({
        name: '',
        editMode: true,
        markedForDeletion: false,
        parent: parentName
      })
    },
    makeStart (item) {
      this.project.home = '/' + item.name
    },
    sortComparator: function () {
      return (a, b) => {
        if (a.children && !b.children) {
          return 1
        } else if (!a.children && b.children) {
          return -1
        }

        return a.name.localeCompare(b.name)
      }
    },
    sortPath (path) {
      let parent = this.findEdge(path)
      parent.children.sort(this.sortComparator())
    },
    findEdge (toPath) {
      let ancestors = ['']

      if (toPath !== '/') {
        ancestors = toPath.split('/')
      }

      let parent = this.project.treeData

      for (let i = 0; i < ancestors.length; i++) {
        if (i === (ancestors.length - 1)) {
          return parent
        } else {
          parent = parent.children[parent.children.findIndex((o) => { return o.name === ancestors[i + 1] })]
        }
      }
      return parent
    },
    showErrorMessage (message) {
      this.doodleForm.errorMessage = message
      this.$_.delay(() => { this.doodleForm.errorMessage = null }, 5000)
    },
    deleteItem (item) {
      if (this.project.home === '/' + item.name) {
        return
      }

      let delMethod = () => {
        let parentEdge = this.findEdge(item.parent)
        parentEdge.children.splice(parentEdge.children.indexOf(item), 1)
      }

      if (item.newFile === true) {
        delMethod()
        return
      }

      this.$dialog
        .confirm('<div class="has-text-centered">' +
          'Are you sure want to delete this ' + ((item.children) ? 'folder' : 'file') + '?</div>', {
          html: true,
          okText: 'Yes',
          cancelText: 'No'
        })
        .then(() => {
          delMethod()
        })
        .catch(() => {})
    },
    updateChildrensParent (item) {
      for (let child of item.children) {
        if (item.parent === '/') {
          child.parent = '/' + item.name
        } else {
          child.parent = item.parent + '/' + item.name
        }

        if (child.children) {
          this.updateChildrensParent(child)
        }
      }
    },
    syncBeforeExecute () {
      this.activeItem.content = window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().getValue()
    },
    isHome (item) {
      let parent = '/'

      if (item.parent && item.parent === '/') {
        parent = item.parent + item.name
      } else if (item.parent) {
        parent = item.parent + '/' + item.name
      }
      return (parent === this.treeHome)
    },
    itemMoved (itemDetails) {
      let itemName = itemDetails.itemName

      let fromPath = itemDetails.fromPath
      let toPath = itemDetails.toPath

      let fromParent = this.findEdge(fromPath)
      let toParent = this.findEdge(toPath)

      if (toParent.isPublicLib) {
        return
      }

      let duplicateFound = false
      let duplicateItem
      let item

      for (let fromChild of fromParent.children) {
        if (fromChild.name === itemName) {
          item = fromChild
          break
        }
      }

      if (this.isHome(item)) {
        this.$dialog.alert("Start/Main file can't be moved, Compiler looks for main method in this folder to execute.", {
          html: true,
          okText: 'Close'
        })
        return
      }

      if (item.isStatic === true) {
        this.$dialog.alert(item.staticMessage, {
          html: true,
          okText: 'Close'
        })
        return
      }

      for (let toChild of toParent.children) {
        if (toChild.name === itemName) {
          duplicateItem = toChild
          duplicateFound = true
          break
        }
      }

      let moveItem = () => {
        fromParent.children.splice(fromParent.children.indexOf(item), 1)
        item.parent = toPath
        toParent.children.push(item)

        if (item.children) {
          this.updateChildrensParent(item)
        }

        this.sortPath(toPath)
      }

      if (duplicateFound) {
        this.$dialog
          .confirm('<div class="has-text-centered">' +
            'File with same name already exists in this folder? \n do you want to replace?</div>', {
            html: true,
            okText: 'Yes',
            cancelText: 'No'
          })
          .then(() => {
            toParent.children.splice(toParent.children.indexOf(duplicateItem), 1)
            moveItem()
          })
          .catch(() => {})
      } else {
        moveItem()
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "~bulma-tooltip/dist/css/bulma-tooltip.min.css";
  @import "~vuejs-dialog/dist/vuejs-dialog.min.css";

  .tree-view-box {
    border: 1px solid #ddd;
    min-height: 100%;
    padding: 0;
    position: relative;
  }

  .tree-top-bar {
    width: 100%;
    background: #e8e8e8;
    height: 30px;
  }

  .float-right {
    float: right;
  }

  .float-left {
    float: left;
  }

  .min-button {
    padding: 0px 0.25em 0px 0.25em;
    font-size: 1em;
    height: 30px;
    margin-right: 0.2em;
  }

  .tree {
    font-size: 0.85em;
    font-weight: 600;
  }

  .confirm-text {
    font-size: 0.75em;
    font-weight: bold;
  }

  .message-box {
    padding: 0.5em;
    color: white;
  }

  .ide-menu-box {
    height: 0;
    overflow: hidden;
    transition: height 0.8s ease-in-out;
    -webkit-transition: height 0.8s ease-in-out;
  }

  .ide-menu-box > .box {
    margin: 1em;
    background: hsl(0, 0%, 96%);
  }

  .ide-menu-box.is-active {
    height: 80px;
  }

  .tree-view-area {
    width: 100%;
    height: calc(100% - 36px);
    position: absolute;
    overflow: scroll;
  }

  .dark {
    .tree-view-area {
      background: black !important;
    }

    .tree-view-box {
      background: black !important;
      border: 1px solid #6c757e;
    }

    .tree-top-bar {
      background: darkgray;
    }

    .tree-item {
      color: lightgray;
    }
  }

</style>
